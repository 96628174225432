import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Progress, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { offlineUrlExpress } from "../../urls";
import LabelSyntheticLoadImage, { DrawRect, SyntheticImageType } from "../Label-Synthetic-LoadImage";
import { AnnotationType } from "../Label-Synthetic-ReCircle";

const maxCalls = 20;
const maxSeconds = 30;

const LabelSyntheticStatus = ({
    processId, datasetId, count, onBack, originAnn
}: {
    processId: string, datasetId: string, count: number, onBack: () => void, originAnn: AnnotationType[]
}) => {
    const {
        increaseImageCount
    } = useActions();

    const { currentUser } = useTypedSelector((state) => state.auth);
    const [images, setImages] = useState<SyntheticImageType[]>([]);
    const [fetching, setFetching] = useState(false);
    const [checkList, setCheckList] = useState<number[]>([]);
    const [targetImage, setTargetImage] = useState<SyntheticImageType | null>(null);
    const [imageSize, setImageSize] = useState([0, 0]);
    const [mount, setMount] = useState(0);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [selectAll, setSelectAll] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [importing, setImporting] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [showOrigin, setShowOrigin] = useState(false);

    const getProcessImageAsync = async () => {
        const res = await fetch(`${offlineUrlExpress}/api/disg/status/` + processId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.json()
    };

    const getFinishAsync = async () => {
        try {
            const result = await getProcessImageAsync();
            setImages([...result.results]);
            setMount((prevCount) => prevCount + 1);
            if (result.Status === 'error' && intervalRef.current) {
                throw new Error(result.ErrorMessage);
            };
            if (result.Status === 'finish' && intervalRef.current) {
                setFetching(false)
                setRefresh(false)
                return clearInterval(intervalRef.current);
            };
        } catch (err: any) {
            setFetching(false)
            message.error(err.message);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                setRefresh(false)

            };
        };
    };

    const handleImportResult = async () => {
        if (checkList.length === 0) {
            return message.warning('There are no images to import.')
        };
        setImporting(true)
        const body = {
            "DatasetId": datasetId,
            "processId": processId,
            "username": currentUser,
            "results": checkList.map(i => images[i])
        };
        try {
            await fetch(`${offlineUrlExpress}/api/disg/importResults`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*', }),
                body: JSON.stringify(body)
            })
            message.success('import Success.');
            increaseImageCount(checkList.length);
            setImporting(false)
        } catch (err: any) {
            message.success(err.message);
            setImporting(false)
        };
    };

    const onChecked = (index: number) => {
        const list = [...checkList, index];
        setCheckList(list);
    };

    const unChecked = (index: number) => {
        const list = [...checkList].filter(i => i !== index);
        setCheckList(list);
    };

    useEffect(() => {
        if (processId && refresh) {
            intervalRef.current = setInterval(getFinishAsync, 3000);
            setFetching(true)
            const timeout = setTimeout(() => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                    setFetching(false)
                    setRefresh(false)
                }
            }, maxSeconds * 1000);
            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current); // 清除 interval 以避免内存泄漏
                    setRefresh(false)
                }
                clearTimeout(timeout); // 清除 timeout
            };
        } else if (!processId) {
            setImages([]);
            setTargetImage(null)
            setCheckList([])
        };
    }, [processId, refresh]);

    useEffect(() => {
        if (mount >= maxCalls && intervalRef.current) {
            setFetching(false)
            clearInterval(intervalRef.current);
        }
    }, [mount]);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        if (checked) {
            setCheckList(images.map((_, index) => index))
        } else {
            setCheckList([])
        }
        setSelectAll(checked)
    };

    useEffect(() => {

        if (images.length !== 0 && checkList.length === images.length) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
    }, [checkList]);

    return (
        <div style={{ width: '100%' }}>

            <div style={{ width: '80%', margin: 'auto' }}>
                <Progress
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                    percent={(images.length) * 100 / count}
                />
            </div>
            <div style={{ width: '80%', margin: 'auto', display: 'flex', textAlign: 'center', gap: 10, justifyContent: 'space-between', height: 50 }}>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', textAlign: 'center' }}>
                    <label htmlFor="select-all-check">Select all</label>
                    <input type="checkbox" id="select-all-check"
                        checked={selectAll}
                        style={{ width: 20, height: 20 }}
                        onChange={handleSelectAll} />
                </div>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', textAlign: 'center' }}>
                    <label htmlFor="show-origin">Show Origin</label>
                    <input type="checkbox" name="input-name" id="show-origin"
                        style={{ width: 20, height: 20 }}
                        checked={showOrigin}
                        onChange={() => setShowOrigin(pre => !pre)} />
                </div>

            </div>

            {targetImage &&
                <div
                    style={{ position: 'absolute', width: '96%', height: 800, zIndex: 999, border: '1px solid', background: 'rgba(0, 0, 0, 0.5)' }}>
                    <Button
                        shape="circle" type='text'
                        icon={<CloseOutlined />}
                        onClick={() => setTargetImage(null)} style={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
                    />
                    <div style={{ width: 700, margin: 'auto', marginTop: 50, position: 'relative' }}>
                        <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <TransformComponent>
                                    <h1>test123</h1>
                                    <img
                                        ref={imgRef}
                                        src={`${offlineUrlExpress}/api/disg/get-image?url=${targetImage?.image}`} alt='pop'
                                        style={{ width: 700, height: 'auto', margin: 'auto' }}
                                        onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                            const target = e.target as HTMLImageElement;
                                            setImageSize([target.naturalWidth, target.naturalHeight])
                                        }}
                                    />
                                    {targetImage.regions.map((region, index) =>
                                        <div key={index} style={{
                                            position: 'absolute', border: '2px solid red', zIndex: 999,
                                            left: (region.TopLeftX / imageSize[0]) * 100 + '%',
                                            top: (region.TopLeftY / imageSize[1]) * 100 + '%',
                                            width: ((region.BottomRightX - region.TopLeftX) / imageSize[0]) * 100 + '%',
                                            height: ((region.BottomRightY - region.TopLeftY) / imageSize[1]) * 100 + '%'
                                        }}
                                        />)}
                                    {showOrigin && originAnn.map((rect, key) =>
                                        <DrawRect key={key} rect={rect} imageSize={imageSize} />
                                    )}
                                </TransformComponent>
                            )}
                        </TransformWrapper>
                    </div>
                </div>
            }
            <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', width: '80%', gap: 5, margin: 'auto', height: 700, overflow: 'auto' }}>
                {images.map((img, index) =>
                    <div
                        key={index}
                        style={{
                            flex: `0 0 32%`,
                            border: '0px solid', padding: 0, justifyContent: 'center', position: 'relative', borderRadius: 10
                        }}
                    >
                        {!targetImage &&
                            <Checkbox
                                style={{ position: 'absolute', top: 8, left: 8, zIndex: 999 }}
                                onChange={(e) => e.target.checked ? onChecked(index) : unChecked(index)}
                                checked={checkList.includes(index)}
                            />}
                        <LabelSyntheticLoadImage image={img} originAnn={originAnn} showOrigin={showOrigin} onShow={(image) => setTargetImage(image)} />
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginTop: 20 }}>
                <Button size="large" onClick={() => { setImages([]); setRefresh(true) }} loading={fetching}>
                    Regenerate
                </Button>
                <Button size="large" onClick={() => setMount(maxCalls)} disabled={!fetching}>
                    Stop
                </Button>
                <Button size="large" style={{ display: 'none' }}>
                    Call Support
                </Button>
                <Button onClick={onBack} size="large">
                    Back
                </Button>
                <Button size="large" onClick={() => handleImportResult()} disabled={fetching} loading={importing}>
                    Import Images
                </Button>

            </div>
        </div>
    )
}

export default LabelSyntheticStatus